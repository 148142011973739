import * as KoButton from "@kobalte/core/button";
import { Component, JSX } from "solid-js";
import styles from "./Button.module.css";

type ButtonVariant =
  | "primary"
  | "secondary"
  | "default"
  | "icon"
  | "icon-selected";

type ButtonProps = {
  children: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  variant?: ButtonVariant;
  size?: "small" | "medium" | "large";
  role?: KoButton.ButtonRootRenderProps["role"];
  label?: string;
  padding?: boolean;
};

const Button: Component<ButtonProps> = (props) => {
  const buttonVariant = () => {
    if (props.variant) {
      switch (props.variant) {
        case "primary":
          return styles.primary;
        case "secondary":
          return styles.secondary;
        case "default":
          return styles.default;
        case "icon":
          return styles.icon;
        case "icon-selected":
          return styles.iconSelected;
      }
    }
  };

  return (
    <KoButton.Root
      aria-label={props.label}
      role={props.role}
      class={`${styles.button} ${buttonVariant()} ${
        props.size ? styles[props.size] : ""
      } ${props.padding ? styles.padding : ""}`}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type ?? "button"}
    >
      {props.children}
    </KoButton.Root>
  );
};

export const AddButton: Component<{
  onClick: () => void;
  disabled?: boolean;
}> = (props) => {
  return (
    <Button variant="icon" onClick={props.onClick} disabled={props.disabled}>
      <svg
        class={styles.addButton}
        width="57"
        height="57"
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28.5" cy="28.5" r="28" />
        <path
          d="M28.5 21.5V35.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.5 28.5H35.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Button>
  );
};

export default Button;
