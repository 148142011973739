import { Database } from "./supabase-types";
import { createClient as _createClient } from "@supabase/supabase-js";
import { Result } from "./common-types";

export function parseAccessToken(accessToken: string) {
  return JSON.parse(atob(accessToken.split(".")[1]));
}

let supabaseClient: ReturnType<typeof _createClient<Database>> | undefined;

export function createClient() {
  if (supabaseClient) {
    return supabaseClient;
  }
  supabaseClient = _createClient<Database>(
    import.meta.env.VITE_SUPABASE_DATABASE_URL,
    import.meta.env.VITE_SUPABASE_ANON_KEY,
  );
  return supabaseClient;
}

export function clearClient() {
  supabaseClient = undefined;
}

export async function getCompanyId(
  supabase: ReturnType<typeof createClient>,
): Promise<Result<string>> {
  // get orders printers cycles for the selected printer
  const session = await supabase.auth.getSession();
  if (session == null || session.error || session.data.session == null) {
    console.error(`Session data is null`, session);
    return { ok: false, error: "Session data is null" };
  }
  const token = parseAccessToken(session.data.session.access_token);
  const companyId = token.user_metadata.companies[0].company_id;
  return { ok: true, value: companyId };
}
